@import "../util/style.scss";

.wrapper {
  // position: absolute;
  width: 100vw;
  height: var(--100vh, 100vh);
  // max-width: 640px;
  overflow-x: hidden;
  background-color: $bgd-lightgrey;
}

.test {
  width: 200px;
  height: 200px;
  background-color: $bgd-white;
}

.frame {
  &-top {
    height: 1.5rem;
  }
  &-slider {
    display: flex;
    width: 100%;
    height: calc(var(--100vh, 100vh) - 264px);
    justify-content: center;
    align-items: center;
    & > * {
      flex-shrink: 0;
    }
  }
  &-ai {
    display: flex;
    width: calc(200% - 3rem);
    height: calc(var(--100vh, 100vh) - 264px);
    justify-content: center;
    align-items: center;
    & > * {
      flex-shrink: 0;
    }
  }
  &-content {
    width: 100%;
    height: 100%;
    max-width: $max-width;
  }
  &-image {
    width: 100%;
  }
  &-bottom {
    height: 3rem;
  }
}
