@import "../util/style.scss";

.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: var(--new100vh);
  overflow: hidden;
}

.frame {
  width: 100%;
  height: 100%;
  @include column(none, none, 0);
  position: absolute;
  color: $col-bgd-w;
  background-color: $col-bgd-b;
  animation: fade-out 5s;
  justify-content: center;
  > * {
    @include txt-bold;
    font-size: 15vw;
    line-height: 15vw;
  }
  opacity: 0;
  pointer-events: none;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
