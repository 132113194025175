@import "../util/style.scss";

.wrapper {
  width: 100%;
}
.hug {
  width: fit-content;
}
.opaque {
  opacity: 0.01;
}

.type {
  &-title {
    color: $txt-white;
    font-family: $font-eng;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  &-title-fill {
    font-family: $font-eng;
    width: fit-content;
    background-color: $bgd-black;
    padding: 0.375rem;
    font-size: 1.3125rem;
    line-height: 1.25rem;
    color: $txt-white;
  }
  &-subtitle {
    color: $txt-white;
    font-family: $font-kor;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 0.875rem;
  }
  &-section {
    color: $txt-white;
    font-family: $font-kor;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1rem;
  }
  &-sentence {
    color: $txt-grey;
    word-break: keep-all;
    font-family: $font-kor;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.25rem;
    min-height: 1rem;
  }
  &-narration {
    font-family: "Galmuri9";
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $txt-white;
    word-break: keep-all;
    min-height: 1.125rem;
    &-black {
      font-family: "Galmuri9";
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: $txt-black;
      word-break: keep-all;
      min-height: 1.125rem;
    }
  }
  &-wrap {
    color: $txt-white;
    font-family: $font-eng;
    font-size: 1rem;
    line-height: 1rem;
  }
  &-warning {
    color: $txt-white;
    font-family: $font-kor;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1rem;
    @include txt-border;
    padding: 0.25rem 0.75rem;
    background-color: $bgd-warning;
  }
}

.right {
  text-align: right;
}

.align {
  &-left {
    text-align: left;
  }
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
}

.white {
  color: $txt-white;
}

.black {
  color: $txt-black;
}

.grey {
  color: $txt-grey;
}

.lightgrey {
  color: $txt-lightgrey;
}
