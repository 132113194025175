@import "../util/style.scss";

.wrapper {
  // position: absolute;
  width: 100vw;
  height: var(--100vh, 100vh);
  // max-width: 640px;
  max-width: $max-width;
  overflow: hidden;
  background-color: $bgd-lightgrey;
}

.frame {
  &-top {
    height: 2rem;
  }
  &-tip {
    width: 100%;
    height: 1rem;
    padding: 1rem 0;
  }
  &-image {
    width: 100%;
    border-radius: 1rem;
  }
  &-map {
    width: calc(100% - 2px);
    height: 100%;
    border: 1px solid $txt-black;
    overflow: hidden;
  }
}
