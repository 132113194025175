@import "../util/style.scss";

.wrapper {
  position: relative;
  min-height: 10rem;
  width: calc(100% - 1px);
  padding: 0.5px;
  box-shadow: 0 0 0 1px inset $txt-black;
  // overflow: hidden;
  margin-bottom: 1rem;
}

.frame {
  &-bar {
    width: 100%;
    box-shadow: 0 0 0 1px $txt-primary;
    &-inside {
      width: 100%;
      height: 100%;
      box-shadow: 0 0 0 1px inset $txt-primary;
      background-color: #758a7933;
    }
  }
  &-average {
    position: absolute;
    height: 100%;
    width: 0px;
    border-right: dashed 1.5px $bgd-black;
    & > * {
      @include font-eng-14;
      position: absolute;
      bottom: -1rem;
    }
  }
}
