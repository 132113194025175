@import "../util/style.scss";

.wrapper {
  position: absolute;
  width: 100vw;
  height: var(--100vh, 100vh);
  // max-width: 640px;
  max-width: $max-width;
  overflow-x: hidden;
  background-color: $bgd-black;
  &::-webkit-scrollbar {
    display: none;
  }
}

.frame {
  &-header {
    width: calc(100% - 2rem);

    position: fixed;
    z-index: 1;
    background-color: #00000070;
    backdrop-filter: blur(3px);
    padding: 0 1rem;
  }
  &-top {
    width: 100%;
    height: 6rem;
  }
  &-scroll {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  &-image {
    width: 100%;
  }
  &-bottom {
    width: 100%;
    height: 5rem;
  }
  &-button {
    width: 100%;
    position: fixed;
    bottom: 0;
    max-width: $max-width;
    pointer-events: none;
  }
  &-btn {
    width: 1.5rem;

    height: 1.5rem;
    box-shadow: 0 0 0 1px inset $txt-white;
    border-radius: 2rem;
    padding: 0.375rem;

    background-color: $bgd-black;
    &.flip {
      transform: rotate(180deg);
    }
    @include shd-fourway-medium;
  }
  &-overlay {
    display: flex;
    z-index: 2;
    width: calc(100% - 2rem);
    padding: 1rem;
    height: calc(100% - 2rem);
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
  }
}
