@import "../util/style.scss";

.wrapper {
  // position: absolute;
  width: 100vw;
  // height: var(--100vh, 100vh);
  // max-width: 640px;
  overflow-x: hidden;
}

.test {
  width: 200px;
  height: 200px;
  background-color: $bgd-white;
}

.frame {
  &-top {
    height: 2rem;
  }
  &-middle {
    height: 3rem;
  }
  &-slider {
    display: flex;
    width: 100%;
    height: calc(var(--new100vh) - 244px);
    justify-content: center;
    align-items: center;
    & > * {
      flex-shrink: 0;
    }
  }
  &-ai {
    width: 300%;
    & > * {
      flex-shrink: 0;
    }
  }
  &-content-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
  }
  &-content {
    background-color: $bgd-white;
    overflow-x: visible;
    max-width: $max-width;
    width: 100%;
    &.scrollable {
      height: 100%;
    }
    &.not-scrollable {
      height: var(--new100vh, 100vh);
      overflow: hidden;
    }
  }
  &-emph {
    width: calc(100% + 0.75rem);
  }
  &-image {
    width: 100%;
    // border-radius: 1rem;
  }
  &-upper {
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    z-index: 100;
    pointer-events: none;
  }
  &-overlay {
    display: flex;
    width: calc(100% - 2rem);
    padding: 1rem;
    height: calc(100% - 2rem);
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 100;
    pointer-events: all;
  }
}
