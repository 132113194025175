@import "../util/style.scss";

.wrapper {
  // position: absolute;
  width: 100vw;
  height: var(--100vh, 100vh);
  max-width: $max-width;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $bgd-primary;
  &::-webkit-scrollbar {
    display: none;
  }
}

.frame {
  &-header {
    width: calc(100% - 2rem);

    position: fixed;
    z-index: 1;
    background-color: #758a7970;
    backdrop-filter: blur(3px);
    padding: 0 1rem;
  }
  &-top {
    width: 100%;
    height: 6rem;
  }
  &-scroll {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-image {
    width: 100%;
  }
  &-bottom {
    width: 100%;
    height: 5rem;
  }
  &-footer {
    width: calc(100% - 2rem);
    height: 3rem;
    padding: 1rem;
    position: fixed;
    bottom: 0;
    max-width: $max-width;
  }
}
