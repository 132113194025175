@import "../util/style.scss";

.wrapper {
  &-white {
    background-color: $bgd-white;
    @include shd-fourway-light;
    width: 100%;
    max-width: calc($max-width - 2rem);
    height: fit-content;
    overflow: hidden;
    box-shadow: 0 0 0 1px $txt-black;
  }
  &-lightgrey {
    background-color: $bgd-lightgrey;
    @include shd-fourway-bold-wide;
    width: 100%;
    max-width: calc($max-width - 2rem);

    height: fit-content;
    overflow: hidden;
    box-shadow: 0 0 0 1px $txt-black;
  }
  &-fixed {
    position: relative;
    width: 100%;
    max-width: calc($max-width - 2rem);

    height: calc(var(--100vh, 100vh) * 0.7);
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    @include shd-fourway-bold-wide;
    &.lightgrey {
      background-color: $bgd-lightgrey;
    }
    &.white {
      background-color: $bgd-white;
    }
  }
}

.frame {
  &-header {
    width: calc(100% - 2rem);
    padding: 1rem;
    position: absolute;
    top: 0;
    z-index: 1;
    backdrop-filter: blur(3px);
    &.lightgrey {
      background-color: rgba(237, 237, 237, 0.9);
    }
  }
  &-popup {
    &-image {
      // height: 10rem;
      width: 100%;
      box-shadow: 0 0 0 1px $txt-black;
    }
  }
  &-icon {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 2rem;
    background-color: $bgd-white;
    @include shd-fourway-light;
    transition: all 0.3s;
    &.emph {
      background-color: $bgd-secondary;
    }
  }
  &-small-image {
    position: relative;
    width: calc(min(25vw, calc(0.25 * $max-width)) - 1.75rem);
    height: calc(min(25vw, calc(0.25 * $max-width)) - 1.75rem);
    box-shadow: 0 0 0 1px inset $txt-black;
    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(min(25vw, calc(0.25 * $max-width)) - 1.75rem);
      height: calc(min(25vw, calc(0.25 * $max-width)) - 1.75rem);
      z-index: -1;
      @include shd-fourway-medium;
    }
    // @include shd-fourway-medium;
  }

  &-normal {
    width: 100%;
    // height: calc(var(--100vh, 100vh) * 0.7);
    // min-height: 100vh;
  }
  &-scroll {
    width: 100%;
    // border-radius: 1rem;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-intro {
    &-02 {
      height: 2.25rem;
      max-width: 100%;
    }
  }
  &-top {
    width: 100%;
    min-height: 2rem;
  }
  &-bottom {
    width: 100%;
    min-height: 4rem;
  }
  &-footer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 2rem);
    padding: 1rem;
    padding-top: 0.25rem;
    height: 3rem;
    z-index: 1;
    backdrop-filter: blur(3px);
    &.lightgrey {
      background-color: rgba(237, 237, 237, 0.9);
    }
  }
}

.text-small {
  max-width: calc(25vw - 1.75rem);
  @include font-kor-12;
  color: $txt-grey;
  text-align: center;
  word-break: keep-all;
  &.condensed {
    letter-spacing: -2px;
  }
}
