@import "../util/style.scss";

.wrapper {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.top {
  margin-top: 9px;
}

.right {
  text-align: right;
}
