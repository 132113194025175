@import "../util/style.scss";

.wrapper {
  padding: 0.75rem;
  height: 1.5rem;
  width: calc(100% - 1.5rem);
  pointer-events: auto;
  position: fixed;
  top: 0;
  backdrop-filter: blur(3px);
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.9);
}

.frame {
  &-menu {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;
    background-color: $txt-white;
    border-radius: 1rem;
    margin-right: 0.75rem;
    @include shd-fourway-medium;
  }
  &-fold {
    rotate: 180;
  }
}

.grey {
  background-color: rgba(237, 237, 237, 0.9);
}

.black {
  background-color: rgba(0, 0, 0, 0.9);
}
