@import "../util/style.scss";

.wrapper {
  position: absolute;
  width: 100vw;
  height: var(--100vh, 100vh);
  max-width: $max-width;
  // max-width: 640px;
  overflow-x: hidden;
  background-color: $bgd-primary;
}

.frame {
  &-scroll {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-image {
    width: 100%;
  }
}
