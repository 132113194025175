@import "../util/style.scss";

.wrapper {
  width: calc(100% - 2rem);
  position: relative;
  @include row(auto, auto, 0.25rem);
  justify-content: center;
  padding: 0.5rem 1rem;
  overflow: visible;
  // border-radius: 1rem;
  transition: all 0.3s;
  background-color: $bgd-white;
  gap: 0;
  box-shadow: 0 0 0 1px inset $txt-black;
}

.frame {
  &-input {
    &::placeholder {
      color: $txt-grey;
      @include font-kor-16;
    }
    &:focus::placeholder {
      color: transparent;
    }
    width: 100%;
    height: 2rem;
    padding: 0;
    color: $txt-black;
    @include font-kor-16;
  }
}
