@import "../util/style.scss";

.wrapper {
  display: flex;
  & > * {
    scroll-snap-align: center;
    flex-shrink: 0;
    flex-grow: 0;
  }
  &:first-child {
    scroll-snap-align: start;
  }
  &:last-child {
    scroll-snap-align: end;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.type {
  &-row {
    flex-direction: row;
    scroll-snap-type: x mandatory;
    height: fit-content;
    width: 100%;
    overflow-x: scroll;
  }
  &-column {
    flex-direction: column;
    scroll-snap-type: y mandatory;
    height: 100%;
    width: fit-content;
    overflow-y: scroll;
  }
}
