@import "../util/style.scss";

.wrapper {
  width: 100%;
  height: var(--new100vh, 100vh);
  max-width: $max-width;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}

.frame {
  &-top {
    width: 100%;
    height: 1rem;
  }
  &-header {
    position: fixed;
    width: 100%;
    z-index: 1;
    backdrop-filter: blur(3px);
    background-color: rgba(237, 237, 237, 0.9);
  }
  &-menu {
    background-color: $txt-white;
    border-radius: 1rem;
    padding: 0.1rem;
  }
  &-rotate {
    rotate: 180deg;
  }
  &-bottom {
    width: 100%;
    height: 3rem;
  }
}
