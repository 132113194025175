@import "../util/style.scss";

.wrapper {
  position: absolute;
  width: 100vw;
  height: var(--100vh, 100vh);
  // max-width: 640px;

  overflow: hidden;
  background-color: $bgd-black;
}

.frame {
  &-image {
    width: 100%;
    position: relative;
    max-width: $max-width;
    &-overlay {
      position: absolute;
      top: 0;
      width: 100%;
      max-width: $max-width;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      opacity: 0;
      transition: all 1s;
      &.visible {
        opacity: 1;
      }
    }
  }
  &-blank {
    height: 300px;
    width: 100%;
    max-width: $max-width;
  }
}

.image {
  width: 100%;
}
