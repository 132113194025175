@import "../util/style.scss";

.wrapper {
  pointer-events: all;
  cursor: pointer;
  &.not-hug {
    position: relative;
    @include row(auto, auto, 0.25rem);
    justify-content: center;
    overflow: visible;

    // border-radius: 1rem;
    transition: all 0.3s;
    // box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.05);
    // &:hover {
    //   box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.1);
    // }
  }
  &.fill-x {
    width: 100%;
  }
}

.link-to {
  width: 100%;
}

.type {
  &-normal {
    font-family: $font-kor;
    font-size: 1rem;
    line-height: 1rem;
    color: $txt-black;
    font-weight: bold;
    padding: 1rem 0;
    width: 100%;
    background-color: $bgd-white;
    box-shadow: 0 0 0 1px inset $txt-black;
    @include shd-fourway-medium;
  }
  &-emph {
    font-family: $font-kor;
    font-size: 1rem;
    line-height: 1rem;
    color: $txt-white;
    font-weight: bold;
    padding: 1rem 0;
    width: 100%;
    background-color: $btn-primary;
    box-shadow: 0 0 0 1px inset $txt-black;
    @include shd-fourway-medium;
  }
  &-emph_white {
    font-family: $font-eng;
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: $txt-white;
    padding: 0.75rem;
    width: calc(100% - 1.5rem);
    background-color: $btn-primary;
    box-shadow: 0 0 0 1px inset $txt-white;
    @include shd-fourway-medium;
  }
  &-download {
    padding: 0.625rem 0;
    & > .frame {
      font-family: $font-eng;
      font-size: 1.5rem;
      line-height: 1.5rem;
      color: $txt-white;
      padding: 0.125rem 0.75rem;
      background-color: $bgd-secondary;
      box-shadow: 0 0 0 1px inset $txt-black;

      @include shd-fourway-bold;
    }
  }
  &-warning {
    font-family: $font-eng;
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: $txt-white;
    @include txt-border;
    padding: 0.75rem;
    width: calc(100% - 1.5rem);
    background-color: $bgd-warning;
    box-shadow: 0 0 0 1px inset $txt-black;
    @include shd-fourway-bold;
  }
}

.clickable {
  &.transparent {
    cursor: pointer;
    &:hover {
      background-color: $col-g95;
      // @include shd-1;
    }
  }
  &.solid {
    cursor: pointer;
    &:hover {
      background-color: $col-g95;
      // @include shd-2;
    }
  }
}

.frame {
  text-align: center;
}

.full {
  width: 100%;
  height: 100%;
}

.icon {
  fill: $col-w;
  height: 1.1rem;
}
